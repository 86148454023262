@import 'node_modules/@rikstv/shared-styles/variables';

$comparison-divider-gray: #e0e2e3;
$comparison-terms-gray: #58595b;
$comparison-product-indent-width: 8px;
$comparison-product-padding: 30px;
$comparison-border-radius: 12px;
$comparison-tablet-brp: 900px;
$comparison-mobile-brp: 767px;

.comparison {

  ul {
    list-style: none; }

  .matrix-variant {
    display: flex; }

  .compact-variant {
    display: none; }

  @media (max-width: $comparison-mobile-brp) {
    .matrix-variant {
      display: none; }

    .compact-variant {
      display: block; } }

  .matrix-variant, .compact-variant {

    .product {
      position: relative;
      overflow: hidden;
      padding-bottom: 100px;
      border-top-left-radius: $comparison-border-radius;
      border-top-right-radius: $comparison-border-radius;
      background-color: $white;
      text-align: center;

      @media (max-width: $comparison-mobile-brp) {
        padding-bottom: 110px; }

      .campaign {
        position: absolute;
        left: 0%;
        right: 0%;
        top: 0%;
        padding: 5px 0 4px;
        background-color: $purple;
        font-size: 10px;
        letter-spacing: 2px;
        color: $white;
        text-transform: uppercase;

        @media (max-width: $comparison-mobile-brp) {
          position: relative;
          left: auto;
          right: auto;
          top: auto; } }

      .name {
        margin-bottom: 16px;
        padding-top: 40px;
        font-size: 14px;

        @media (max-width: $comparison-mobile-brp) {
          padding-top: 30px; } }

      .price {
        margin: 0;
        font-size: 32px;
        font-weight: 500;

        span {
          margin-left: 8px;
          font-size: 16px;
          font-weight: 300; } }

      .terms {
        margin: 0 0 24px;
        padding: 0 10%;
        font-size: 12px;
        color: $comparison-terms-gray; }

      .description {
        margin: 0 0 24px;
        padding: 0 $comparison-product-padding;
        font-size: 16px; }

      .actions {
        display: flex;
        position: absolute;
        left: $comparison-product-padding;
        right: $comparison-product-padding;
        bottom: 50px;
        flex-direction: column;

        @media (max-width: $comparison-mobile-brp) {
          bottom: 20px; }

        > * {
          margin: 0 auto 4px;

          &:first-child {
            width: 100%;
            padding-right: 0;
            padding-left: 0; }

          &:last-child {
            margin-bottom: 0; } }

        .edge-btn.purple.secondary {
          color: $black; } } }

    .features {

      li {
        display: block;
        position: relative;

        h3 {
          padding: 0 16px 0 38px;
          font-size: 16px;
          font-weight: 300;
          white-space: nowrap;

          &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0%;
            top: 50%;
            width: 28px;
            height: 28px;
            background-repeat: no-repeat;
            background-position: center center;
            transform: translateY(-50%); }

          @each $variant in ('antenna' 137%, 'app' 150%, 'apple-tv' 150%, 'assembly' 170%, 'card-reader' 180%, 'channels' 130%, 'c-more' 120%, 'eurosport' 114%, 'hbo-nordic' 140%, 'movie-rental' 140%, 'paramount-plus' 120%, 'rooms' 130%, 'set-top-box' 150%, 'signal-coverage-guarantee' 150%, 'streaming-services' 130%, 'tv2-sport' 120%, 'viaplay' 120%, 'viasport' 120%, 'web-tv' 110%) {
            &.#{nth($variant, 1)}::before {
              background-image: url('./images/feature-icons/#{nth($variant, 1)}.svg');
              background-size: #{nth($variant, 2)} auto; } }

          &.eurosport::before {
            background-position: 75% 50%; } }

        > span {
          position: absolute;
          right: 0%;
          top: 50%;
          transform: translateY(-50%); } } }

    .indicator {
      position: relative;

      span {
        display: block;

        &.text {
          min-width: 20px;
          font-size: 16px;
          white-space: nowrap;
          text-align: center;

          &.subtle {
            opacity: .6;
            color: $comparison-terms-gray; } }

        &.icon {
          display: block;
          width: 20px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 90%;

          &::before {
            content: '';
            display: block;
            padding: 100% 0 0; }

          &.check {
            background-image: url('./images/check.svg'); } } } } }

  .matrix-variant {
    flex-wrap: wrap;

    .introduction, .features {
      width: 30%; }

    .products, .feature-indicators {
      width: 70%; }

    .introduction {
      margin: 0;
      padding: 32px 5% 0 2px;
      text-align: left;

      h1, h2 {
        font-size: 24px;

        &.pre-heading {
          font-size: 14px; } } }

    @media (max-width: $comparison-tablet-brp) {
      .introduction, .features {
        width: 20%; }

      .products, .feature-indicators {
        width: 80%; }

      .introduction > * {
        display: none; } }

    .products {
      display: flex;

      > li {
        display: flex;
        padding: 0 $comparison-product-indent-width;
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;

        .product {
          width: 100%;

          @media (max-width: 1050px) {
            .description {
              padding: 0 $comparison-product-padding / 2; }

            .actions {
              left: $comparison-product-padding / 2;
              right: $comparison-product-padding / 2; } } } } }

    .features li, .feature-indicators > li > ul > li {
      height: 50px;

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: - $comparison-product-indent-width;
        right: - $comparison-product-indent-width;
        bottom: 0px;
        height: 1px;
        background-color: $comparison-divider-gray; } }

    .feature-indicators > li:last-child > ul > li::after {
      right: 5px; }

    .features li h3 {
      position: absolute;
      left: 0%;
      top: 50%;
      width: 100%;
      transform: translateY(-50%);

      @media (max-width: $comparison-tablet-brp) {
        padding-left: 0;

        &::before {
          display: none; } } }

    .feature-indicators {
      display: flex;

      > li {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;

        > ul {
          padding: 0 $comparison-product-indent-width;

          > .indicator {
            background-color: $white;

            span {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%); } } }

        .choose {
          padding: 0 $comparison-product-indent-width;

          > div {
            padding: 18px 0 28px;
            border-bottom-left-radius: $comparison-border-radius;
            border-bottom-right-radius: $comparison-border-radius;
            background-color: $white;
            text-align: center; } } } } }

  .compact-variant {

    .introduction {
      margin: 0 0 40px; }

    .products > li {
      width: 100%;
      max-width: 360px;
      margin: 0 auto 70px;
      border-bottom-left-radius: $comparison-border-radius;
      border-bottom-right-radius: $comparison-border-radius;
      background-color: $white;

      &:last-child {
        margin-bottom: 0; }

      .description {
        margin-bottom: 20px; }

      .features {
        margin-bottom: 30px;
        padding: 0 $comparison-product-padding;

        li {
          padding: 10px 0 12px;
          border-bottom: 1px solid $comparison-divider-gray;
          text-align: left;

          &:last-child {
            border-bottom: 0; }

          .indicator {
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%); } } }

      &.chosen {
        background-color: $purple; // TODO(saasen@23.07.2019): This doesn't look good. Replace with proper styling.
        color: $white; } } } // TODO(saasen@23.07.2019): This doesn't look good. Replace with proper styling.

  .conditions {
    margin: 30px auto 0;
    padding: 0 2% 0 32%;
    font-size: 14px;
    color: $comparison-terms-gray;
    text-align: center;

    @media (max-width: $comparison-tablet-brp) {
      padding: 0 2% 0 22%; }

    @media (max-width: $comparison-mobile-brp) {
      max-width: 360px;
      padding: 0; } } }
