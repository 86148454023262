@import '~@rikstv/shared-styles/variables';

@keyframes product-description-delayed-appearence {
  0% {
    opacity: 0; }
  25% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.product-description {
  display: none;
  position: absolute;
  left: 26px;
  top: 32px;
  z-index: 1;
  width: 370px;
  padding: 20px 24px;
  border-radius: 16px;
  box-shadow: 0 7px 16px 0 rgba($black, .2);
  background-color: $white;
  font-weight: 300; }

.show-product-description {
  display: inline-block;
  position: relative;
  z-index: 2;
  width: 17px;
  height: 17px;
  border: 0;
  outline: 0;
  background-color: transparent;
  background-image: url('./images/info.svg');
  background-size: 100%;
  transform: translate(5px, 3px);

  &::after {
    content: '';
    display: none;
    position: absolute;
    left: 2px;
    top: 18px;
    width: 0;
    height: 0;
    border-right: 6px solid transparent;
    border-bottom: 9px solid $white;
    border-left: 6px solid transparent; }

  &:hover {
    + .product-description, &::after {
      display: block;
      opacity: 0;
      animation-name: product-description-delayed-appearence;
      animation-duration: .4s;
      animation-fill-mode: forwards; } } }
