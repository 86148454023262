.resources {
  display: flex;
  margin-top: 8vh;
  justify-content: space-between;

  .introduction {
    width: 40%;

    h1 {
      margin-bottom: 24px;
      font-size: 28px; }

    p {
      font-size: 24px; } }

  .links {
    width: 50%;

    ul {
      margin-bottom: 1em; } }

  @media (max-width: 640px) {
    margin-top: 0;
    flex-direction: column;

    .introduction, .links {
      width: 100%; }

    > *:not(:last-child) {
      margin-bottom: 30px; } } }
