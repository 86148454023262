@import '~@rikstv/shared-styles/variables';

$notifications-green: #329D6E;

.notifications {
  position: absolute;
  left: 0%;
  top: 100%;
  width: 100%;

  ul {
    list-style: none;

    li {
      padding: 4px 30px;

      span {
        display: block;
        max-width: 1400px;
        margin: 0 auto;
        color: $white; }

      &.error {
        background-color: $red; }

      &.success {
        background-color: $notifications-green; } } } }
