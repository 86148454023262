@import '~@rikstv/shared-styles/style';

.page {
  display: flex;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 40px;

  @media (max-width: $tablet) {
    padding: 0 20px; }

  main {
    flex-grow: 1;

    > .title {
      position: relative;
      padding-left: 39px;
      font-size: 14px;
      letter-spacing: 3px;
      text-transform: uppercase;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0px;
        top: 7px;
        width: 30px;
        height: 2px;
        background-color: $gray-dark; }

      + * {
        margin-top: 30px;

        &.subscription {
          margin-top: 22px;

          + * {
            margin-top: 38px; } } } }

    .subscription {
      margin-left: 37px;
      font-size: 32px;
      text-transform: uppercase; } }

  aside {
    width: 34%;
    margin-left: 9%;
    padding-top: 39px; }

  @media (min-width: ($tablet + 1px)) {
    &.sticky-aside {
      main {
        padding-right: 43%; }

      aside {
        position: fixed;
        right: 40px; } } }

  @media (min-width: 1400px) {
    &.sticky-aside aside {
      right: calc(50% - ((1400px / 2) - 40px));
      width: 475px; } }

  @media (max-width: $tablet) {
    flex-direction: column;

    aside {
      width: 100%;
      margin-left: 0; } }

  main > .personalia-form {
    padding: 30px; } }
