@import '~@rikstv/shared-styles/style';

$form-divider-gray: #ebebeb;

body {
  max-width: none;
  padding-top: 120px;
  padding-bottom: 80px;
  background-color: $gray;
  font-size: 16px;

  @media (min-width: $tablet) {
    box-shadow: 0px 0px 0px 16px $white inset; }

  a, a:active, a:visited {
    color: $gray-dark; }

  hr {
    height: 1px;
    margin: 40px 0;
    border: 0;
    background-color: $form-divider-gray; }

  form {

    hr {
      margin: 60px 0 50px; }

    .dual {
      display: flex;
      justify-content: space-between;

      &:last-child > * {
        margin-bottom: 0; }

      > *:first-child {
        margin-right: 16px; } }

    .short {
      width: calc(50% - 8px); } }

  header {
    z-index: 2; }

  .modal {
    z-index: 3; }

  .router {
    position: relative;
    z-index: 1; }

  &.modal-open {
    overflow: hidden; } }

.edge-btn:disabled.working {
  background-image: url('./images/spinner-dotted.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 40px;
  color: rgba($gray-dark, 0); }

@media (min-width: $tablet) {
  .scroll-wrapper {
    position: relative;
    border-bottom: 2px solid $gray-dark;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0%;
      bottom: 0%;
      width: 100%;
      height: 40px;
      background-image: linear-gradient(rgba($gray, 0), rgba($gray, 1)); } }

  .scrollable {
    overflow-y: auto;
    max-height: calc(100vh - 310px);
    padding-bottom: 30px; } }
