@import '~@rikstv/shared-styles/variables';
@import '~@rikstv/shared-styles/mixins';

$category-gray: #58595b;
$category-hr-gray: #ebebeb;

.category {
  position: relative;
  border: 2px solid $white;
  border-radius: 12px;
  background-color: $white;
  transition: border .2s ease-in-out;

  @for $i from 1 to 8 {
    &:nth-child(#{$i}) {
      z-index: 8 - $i; } }

  &:not(:last-child) {
    margin-bottom: 30px; }

  &.invalid {
    border: 2px solid $red; }

  &.expandable, &.editable {

    .head {
      position: relative;
      user-select: none;

      &.clickable {
        cursor: pointer; }

      .actions {
        position: absolute;
        right: 24px;
        top: 48%;
        transform: translateY(-50%);

        .toggle-expanded, .toggle-editing {
          width: 40px;
          height: 40px;
          border: 0;
          outline: 0;
          background-color: transparent;
          background-repeat: no-repeat;
          background-position: center center;
          transition: all .2s ease-in-out;
          cursor: pointer;
          @include hide-text; }

        .toggle-expanded {
          background-image: url('./images/expand.svg');
          background-size: 80%; }

        .toggle-editing {
          background-image: url('./images/edit.svg');
          background-size: 64%; } } }

    > .toggle-editing {
      position: absolute;
      right: 30px;
      bottom: 30px; } }

  &.expandable.expanded .actions .toggle-expanded {
    transform: rotate(180deg); }

  &.editing {
    padding-bottom: 80px; }

  .title {
    padding: 22px 36px;
    font-size: 24px;
    font-weight: 300; }

  > .content {
    padding: 15px 38px 30px;

    > .error {
      position: relative;
      padding-left: 50px;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0px;
        top: 1px;
        width: 25px;
        height: 25px;
        background-image: url('./images/error.svg'); } } }

  .personalia-preview {
    list-style: none;

    li {
      display: flex;

      &:not(:last-child) {
        margin-bottom: 6px; }

      > * {
        font-size: 16px;
        font-weight: 300; }

      h4 {
        width: 140px; }

      p {
        flex-grow: 1; } } } }
