@import '~@rikstv/shared-styles/mixins';

@keyframes loader-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(365deg); } }

.loader {
  width: 20px;
  height: 20px;
  background-image: url('./images/loader.svg');
  background-size: 100%;
  animation: loader-spin .3s linear infinite;
  @include hide-text;

  &.fixed {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 60px;
    height: 60px;
    margin: -30px 0 0 -30px; } }
