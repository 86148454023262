@import '~@rikstv/shared-styles/variables';

$progress-purple-light: #3a405b;
$progress-gray: #58595b;

.progress {
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style: none;

  li {
    position: relative;

    button {
      display: block;
      width: 138px;
      height: 32px;
      border: 1px solid $purple;
      border-radius: 16px;
      outline: 0;
      background-color: $white;
      font-size: 11px;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: 1px;
      white-space: nowrap;
      color: $progress-purple-light;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;

      &::after {
        content: '';
        display: none;
        width: 10px;
        height: 7px;
        margin-left: 8px;
        background-image: url('./images/check-black.svg'); }

      &:disabled {
        border: 1px solid rgba($progress-gray, .6);
        color: rgba($progress-gray, .6); } }

    &:hover button:not(:disabled) {
      text-decoration: underline; }

    &:not(:first-child) {
      padding-left: 38px;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0%;
        top: 50%;
        width: 38px;
        height: 1px;
        margin-top: -1px;
        background-color: rgba($black, .1); }

      @media (max-width: $tablet) {
        padding-left: 18px;

        &::before {
          width: 18px; } } }

    &.completed {
      button {
        position: relative;

        &::after {
          display: inline-block; } }

      ~ li::before {
        background-color: $purple; } }

    &.active button {
      border: 1px solid $purple;
      background-color: $purple;
      color: $white;

      &::after {
        background-image: url('./images/check-white.svg'); } } } }
