@import '~@rikstv/shared-styles/variables';

$input-field-border-gray: #c9c9c9;
$input-field-label-gray: #58595b;

.input-field {
  position: relative;
  width: 100%;
  margin: 0 0 26px;

  .label-wrapper label, input {
    font-size: 16px;
    font-weight: 300;
    line-height: 1; }

  .label-wrapper {
    position: relative;
    width: 100%;
    height: 15px;

    label {
      display: block;
      position: absolute;
      left: 16px;
      top: 0px;
      z-index: 99;
      color: $input-field-label-gray;
      transform: translateY(27px);
      transition: all .2s ease-out;
      cursor: text; } }

  input {
    position: relative;
    width: 100%;
    height: 40px;
    padding: 0 15px;
    border: 1px solid $input-field-border-gray;
    border-radius: 8px;
    outline: none;
    color: $black;
    transition: border .2s ease-out;
    appearance: none; }

  .error {
    position: absolute;
    left: 16px;
    bottom: -22px;
    font-size: 12px;
    color: $red; }

  &.has-focused {

    .label-wrapper label {
      font-size: 12px;
      transform: translateY(0px); } }

  &.active, &:hover {

    input {
      border: 1px solid $black; }

    .label-wrapper label {
      color: $black; } }

  &.has-error {

    input {
      border: 1px solid $red; }

    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 14px;
      top: 24px;
      width: 21px;
      height: 21px;
      background-image: url('./images/error-indicator.svg');
      background-size: 100%; } } }
