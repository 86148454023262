@import '~@rikstv/shared-styles/variables';
@import '~@rikstv/shared-styles/mixins';

$product-list-gray: #58595b;
$product-list-hr-gray: #ebebeb;

.product-list {
  position: relative;
  z-index: 2;
  list-style: none;

  > li {
    position: relative;
    padding-left: 50px;
    font-weight: 500;
    color: $black;

    &:not(:last-child) {
      margin-bottom: 30px; }

    @for $i from 1 to 16 {
      &:nth-child(#{$i}) {
        z-index: 16 - $i; } }

    span {
      margin-left: 6px;
      font-weight: 300;
      color: $product-list-gray; }

    > div:not(.product-description) {
      display: inline-block; } }

  &.checklist > li::before {
    content: '';
    display: block;
    position: absolute;
    left: 0px;
    top: 2px;
    width: 24px;
    height: 24px;
    background-image: url('./images/check.svg');
    background-size: 100%; }

  &.selectable > li {
    cursor: pointer;
    user-select: none;

    .checkbox, .radiobutton {
      position: absolute;
      left: 0px;
      top: 1px; } }

  + .product-list {
    z-index: 1;
    padding-top: 80px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0px;
      top: 40px;
      width: 100%;
      height: 1px;
      background-color: $product-list-hr-gray; } } }
