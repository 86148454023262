@import '~@rikstv/shared-styles/variables';

.checkbox {
  display: block;
  position: relative;
  width: 24px;
  height: 24px;
  border: 1px solid $black;
  border-radius: 2px;
  background-color: $white;
  perspective: 20px;
  transition: background-color .2s linear;

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 14px;
    height: 11px;
    margin: -5px 0 0 -7px;
    background-image: url('./images/check.svg');
    transform: rotateY(-90deg) translateY(2px);
    transition: all .1s linear; }

  &.toggled {
    border: 1px solid $purple;
    background-color: $purple;

    &::after {
      transform: rotateY(0deg) translateY(0px); } } }
