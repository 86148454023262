@import '~@rikstv/shared-styles/variables';
@import '~@rikstv/shared-styles/mixins';


$header-nav-gray: #585858;
$header-nav-bg-gray: #F2F1EF;

header {
  display: flex;
  position: fixed;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 64px;
  padding: 0 20px;
  border-bottom: 2px solid rgba($black, .1);
  align-items: center;
  background-color: $white;

  .contain-wrapper {
    display: flex;
    max-width: 1920px;
    margin: 0 auto;
    align-items: center;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between; }

  .logo {
    width: 82px;
    background-image: url('https://static.rikstv.no/web/images/rikstv-logo-red.svg');
    background-repeat: no-repeat;
    background-size: 100%;

    @media (max-width: $tablet) {
      display: none; }

    &::before {
      content: '';
      display: block;
      padding: 28% 0 0; } }

  .top-level-navigation {
    display: flex;
    list-style: none;

    li a {
      display: inline-block;
      height: 40px;
      padding: 6px 44px 0;
      border-radius: 20px;
      text-decoration: none;

      &:not(.active) {
        color: $header-nav-gray; }

      &.active {
        background-color: $header-nav-bg-gray;
        font-weight: 500; } } }

  .abort-session {
    width: 33px;
    height: 33px;
    margin-left: 49px;
    border: 0;
    outline: 0;
    background-color: transparent;
    background-image: url('./images/close.svg');
    cursor: pointer;
    appearance: none;
    @include hide-text; }

  &.top-level .logo {
    margin-right: 174px; } }
