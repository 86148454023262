@import '~@rikstv/shared-styles/variables';
@import '~@rikstv/shared-styles/mixins';

$profile-icon-gray: #404042;
$profile-menu-hover-gray: #f9f9f9;

.profile {
  display: flex;
  position: relative;
  align-items: center;

  .name {
    overflow: hidden;
    width: 200px;
    margin-right: 14px;
    font-size: 18px;
    white-space: nowrap;
    text-align: right;
    text-overflow: ellipsis; }

  .toggle-menu {
    width: 42px;
    height: 42px;
    border: 1px solid $profile-icon-gray;
    border-radius: 50%;
    outline: 0;
    background-color: transparent;
    background-image: url('./images/settings.svg');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    appearance: none;
    @include hide-text; }

  .click-field {
    position: fixed;
    left: 0%;
    top: 0%;
    z-index: 1;
    width: 100%;
    height: 100%; }

  .menu {
    position: absolute;
    right: 0px;
    top: 52px;
    z-index: 2;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0px 6px 10px rgba($black, .15);
    background-color: $white;
    list-style: none;

    li {
      width: 150px;

      > * {
        display: block;
        width: 100%;
        padding: 6px 24px;
        border: 0;
        outline: 0;
        background-color: transparent;
        font-size: 16px;
        font-weight: 300;
        text-align: center;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          background-color: $profile-menu-hover-gray; } }

      &:first-child > * {
        padding-top: 15px; }

      &:last-child > * {
        padding-bottom: 15px; } } } }
