@import '~@rikstv/shared-styles/variables';
@import '~@rikstv/shared-styles/mixins';

@mixin truncate-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

$users-divider-gray: #e0e2e3;

.users {
  position: relative;
  margin: 0 auto;
  padding-bottom: 80px;

  .heading {
    margin: 80px 0;
    text-align: center;

    h1 {
      margin-bottom: 16px;
      font-size: 28px; }

    h2 {
      font-size: 24px;
      font-weight: 300; } }

  ul {
    list-style: none;

    @media (min-width: $tablet) {
      &.scrollable {
        max-height: calc(100vh - 550px); } }

    li.headers {
      display: flex;
      margin-bottom: 14px;
      justify-content: flex-end;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 2px;
      text-align: center;
      text-transform: uppercase;

      .role {
        width: 10%; }

      .email {
        width: 40%;
        margin-right: 50px; } }

    li:not(.headers) {
      display: flex;
      margin-bottom: 16px;
      align-items: center;

      &:not(:last-child) {
        border-bottom: 1px solid $users-divider-gray; }

      > * {
        font-size: 16px;
        font-weight: 300; }

      .name {
        position: relative;
        padding: 15px 0 15px 60px;
        flex-grow: 1;
        @include truncate-text;

        .profile {
          position: absolute;
          left: 0px;
          top: 50%;
          width: 40px;
          border-radius: 50%;
          transform: translateY(-50%); } }

      .role {
        width: 10%;
        text-align: center; }

      .email {
        width: 40%;
        text-align: center;
        @include truncate-text; }

      .actions {
        display: flex;
        width: 50px;
        align-items: center;
        justify-content: flex-end;

        .delete {
          @include hide-text;
          width: 18px;
          height: 18px;
          border: 0;
          outline: 0;
          background-color: transparent;
          background-image: url('./images/trash-can.svg');
          cursor: pointer; } } } }

  button.new-user {
    position: absolute;
    right: 0px;
    bottom: 0px; } }
