@import '~@rikstv/shared-styles/variables';

$animated-checkmark-green: #329d6e;
$animated-checkmark-curve: cubic-bezier(.65, 0, .45, 1);

@keyframes animated-checkmark-stroke-anim {
  100% {
    stroke-dashoffset: 0; } }

@keyframes animated-checkmark-scale-anim {
  0%, 100% {
    transform: none; }

  50% {
    transform: scale3d(1.1, 1.1, 1); } }

@keyframes animated-checkmark-fill-anim {
  100% {
    box-shadow: inset 0px 0px 0px 30px $animated-checkmark-green; } }

.animated-checkmark .checkmark {
  display: block;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px $animated-checkmark-green;
  animation: animated-checkmark-fill-anim .4s ease-in-out .4s forwards, animated-checkmark-scale-anim .3s ease-in-out .9s both;
  stroke-width: 2;
  stroke: $white;
  stroke-miterlimit: 10;

  .circle {
    animation: animated-checkmark-stroke-anim .6s $animated-checkmark-curve forwards;
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: $animated-checkmark-green;
    fill: none; }

  .check {
    transform-origin: 50% 50%;
    animation: animated-checkmark-stroke-anim .3s $animated-checkmark-curve .8s forwards;
    stroke-dasharray: 48;
    stroke-dashoffset: 48; } }
