@import '~@rikstv/shared-styles/variables';

.radiobutton {
  display: block;
  position: relative;
  overflow: hidden;
  width: 24px;
  height: 24px;
  border: 1px solid $black;
  border-radius: 12px;
  background-color: $white;
  transition: border .2s linear;

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: $white;
    transform: translate(-50%, -50%);
    transition: background-color .2s linear; }

  &.toggled {
    border: 2px solid $purple;

    &::after {
      background-color: $purple; } } }
