@import '~@rikstv/shared-styles/variables';
@import '~@rikstv/shared-styles/mixins';

.modal {
  position: fixed;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba($black, .3);
  backdrop-filter: blur(6px);

  .modal-box {
    position: absolute;
    left: 50%;
    top: 50%;
    width: calc(100% - 40px);
    max-width: 400px;
    padding: 40px;
    border-radius: 10px;
    background-color: $white;
    text-align: center;
    transform: translate(-50%, -50%);

    h2 {
      margin-bottom: 16px;
      font-size: 24px; }

    p {
      margin-bottom: 20px;
      font-size: 16px; }

    .actions {
      display: flex;
      justify-content: center;

      > *:first-child {
        margin-right: 16px; } }

    &.order-confirmation {
      max-width: 640px;
      padding: 40px 140px 40px 200px;
      text-align: left;

      p {
        margin: 0; }

      .close {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 17px;
        height: 15px;
        border: 0;
        outline: 0;
        background-image: url('./images/close.svg');
        cursor: pointer;
        appearance: none;
        @include hide-text; }

      .animated-checkmark {
        position: absolute;
        left: 108px;
        top: 50%;
        transform: scale(1.2) translate(-50%, -50%); } }

    &.create-user .actions {
      margin-top: 50px; } } }
