@import '~@rikstv/shared-styles/variables';

$customer-search-gray: #58595b;

.customer-search {
  position: relative;
  overflow: hidden;
  padding-right: 40px;
  border: 1px solid $customer-search-gray;
  border-radius: 19px;
  background-color: $white;

  &::after {
    content: '';
    display: block;
    position: absolute;
    right: 12px;
    top: 11px;
    width: 18px;
    height: 17px;
    background-image: url('./images/magnifying-glass.svg'); }

  input {
    width: 400px;
    height: 38px;
    padding-left: 24px;
    border: 0;
    border-right: 1px solid $customer-search-gray;
    outline: 0;
    font-size: 16px;
    font-weight: 300; } }
