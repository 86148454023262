@import '~@rikstv/shared-styles/variables';

$edit-receiver-light-gray: #eff0f1;
$edit-receiver-gray: #58595b;

.edit-receivers {
  padding-top: 22px;
  list-style: none;

  > li {
    display: flex;
    position: relative;

    @for $i from 1 to 12 {
      &:nth-child(#{$i}) {
        z-index: 12 - $i; } }

    &:not(:last-child) {
      margin-bottom: 20px; }

    &:first-child > ul > li {
      position: relative;

      &::after {
        display: block;
        position: absolute;
        left: 0px;
        top: -28px;
        width: 100%;
        font-size: 12px;
        font-weight: 300;
        color: $black;
        text-align: center; }

      @for $i from 1 through 4 {
        &:nth-child(#{$i})::after {
          content: 'Nr. #{$i}'; } } }

    > * {
      flex-basis: 0; }

    > span {
      position: relative;
      padding-top: 10px;
      flex-grow: 1;
      font-weight: 500;

      .product-description {
        top: 42px; } }

    > ul {
      display: flex;
      flex-grow: 2;
      list-style: none;

      > li {
        padding: 8px 0;
        border: 1px solid $edit-receiver-light-gray;
        border-radius: 10px;
        flex-basis: 0;
        flex-grow: 1;
        background-color: $edit-receiver-light-gray;
        color: $black;
        text-align: center;
        transition: all .2s ease-out;
        cursor: pointer;
        user-select: none;

        &:not(:last-child) {
          margin-right: 3px; }

        &.chosen {
          background-color: $purple;
          font-weight: 500;
          color: $white; }

        &.disabled {
          border: 1px solid $edit-receiver-light-gray;
          background-color: $white;
          color: $edit-receiver-gray;
          cursor: default; }

        &.not-selectable {
          border: 0; } } } } }
