@import '~@rikstv/shared-styles/variables';

$price-summary-gray: #58595b;

.price-summary {

  .categories {
    list-style: none;

    > li {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid $price-summary-gray;

      > h3 {
        margin: 0 0 12px;
        font-size: 16px;
        font-weight: 500;
        color: $black;
        text-transform: uppercase; }

      .lines {
        list-style: none;

        li {
          position: relative;
          color: $price-summary-gray;

          span {
            position: absolute;
            right: 0px;
            top: 0px; }

          .included {
            list-style: none;

            li {
              padding-left: 20px;

              &::before {
                content: '- ';
                display: inline; } } } } }

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;

        .lines li {
          font-weight: 500;
          color: $black; } } } }

  .step-navigation {
    display: flex;
    margin-top: 30px;
    justify-content: space-between; } }
